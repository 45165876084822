<template>
  <div>
    <!--    营业执照信息-->
    <div class="topDiv">
      <span class="longTextBtn finger btnReset"> 模版下载 </span>
      <span class="longTextBtn finger btnReset marginLeft20"> 模版导入 </span>
    </div>
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="营业执照"></FromLabel>
        <div class="formFlIpt578 fl">
          <Input v-model="data.image_file_name" clearable @on-clear="clearUploadFile" readonly class="iviewIptWidth307 clearMr45" placeholder="仅支持jpg.gif.png等格式的图片">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey(...arguments)"></FormUpload>
            </span>
          </Input>
        </div>
        <FromLabel label="上传合同"></FromLabel>
        <div class="formFrIpt fl">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.gif.png等格式的图片">
            <span slot="append" class="finger">
              <FormUpload label="上传"></FormUpload>
            </span>
          </Input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="公司名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="类型" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="是否为供应商"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="住所"></FromLabel>
        <div class="allWidth">
          <Select v-model="type" class="iviewIptWidth165 marginRight10">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
          <Select v-model="type" class="iviewIptWidth165 marginRight10">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="成立时间"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
        <FromLabel label="注册资本" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
          <span class="afterWords10">万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="营业期限"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165"></DatePicker>
          <span class="afterWords14">至</span>
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165"></DatePicker>
          <Checkbox v-model="prolong" style="margin-left:20px;">长期</Checkbox>
        </div>
        <FromLabel label="登记时间"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="经营范围" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075"></i-input>
        </div>
      </div>
      <!-- <div class="formLine borderTopNone ">
        <FromLabel label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075"></i-input>
        </div>
      </div> -->
      <div class="formLine borderTopNone ">
        <FromLabel label="资料维护"></FromLabel>
        <div class="allWidth">
          <CheckboxGroup v-model="license">
            <Checkbox label="1">生产许可证</Checkbox>
            <Checkbox label="2">生产备案凭证</Checkbox>
          </CheckboxGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
export default {
  name: 'businessLicenseInfoF',
  components: {
    FromLabel,
    FormUpload
  },
  data() {
    return {
      num: 1,
      type: '',
      license: [],
      prolong: false,
      data: {}
    }
  },
  methods: {
    // 清空上传文件
    clearUploadFile() {
      delete this.data.image_files
      delete this.data.image_file_name
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url, field) {
      // console.log(key, file_name, field)
      this.data.image_files = key
      this.data.image_file_name = file_name
    }
  }
}
</script>

<style scoped lang="less">
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
</style>
